exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artikel-js": () => import("./../../../src/pages/artikel.js" /* webpackChunkName: "component---src-pages-artikel-js" */),
  "component---src-pages-buecher-js": () => import("./../../../src/pages/buecher.js" /* webpackChunkName: "component---src-pages-buecher-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interview-ueberbitten-js": () => import("./../../../src/pages/interview-ueberbitten.js" /* webpackChunkName: "component---src-pages-interview-ueberbitten-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lesereise-js": () => import("./../../../src/pages/lesereise.js" /* webpackChunkName: "component---src-pages-lesereise-js" */),
  "component---src-pages-presse-fotos-js": () => import("./../../../src/pages/presse-fotos.js" /* webpackChunkName: "component---src-pages-presse-fotos-js" */),
  "component---src-pages-presse-mappe-miriam-js": () => import("./../../../src/pages/presse-mappe-miriam.js" /* webpackChunkName: "component---src-pages-presse-mappe-miriam-js" */),
  "component---src-pages-presse-mappe-ueberbitten-js": () => import("./../../../src/pages/presse-mappe-ueberbitten.js" /* webpackChunkName: "component---src-pages-presse-mappe-ueberbitten-js" */),
  "component---src-pages-presse-mappe-unorthodox-js": () => import("./../../../src/pages/presse-mappe-unorthodox.js" /* webpackChunkName: "component---src-pages-presse-mappe-unorthodox-js" */)
}

